<template>
  <div>
    <el-dialog title="导入教师"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               v-if="dialogVisible"
               :rules="rules"
               label-width="160px">

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="导入文件">
              <el-button type="primary"
                         style="padding:10px 30px"
                         @click="checkFile"
                         v-if="!file.name">选择</el-button>
              <el-tag @close="clear"
                      v-else
                      closable
                      type="priamry">
                {{file.name?file.name:''}}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="结束时间"
                          prop="end_time">
              <el-date-picker v-model="form.end_time"
                              type="datetime"
                              style="width:100%"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              default-time="23:59:59"
                              placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      file: {},
      form: {},
      dialogVisible: false,
      rules: {

        end_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

      },
      districtList: [],
      userType: []
    }
  },
  methods: {
    clear () {
      this.file = {}
    },
    handleClose () {
      this.file = {}
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      if (this.file == {}) {
        this.$message.error('请选择文件')
        return
      }
      this.$refs.formRef.validate(val => {
        if (!val) return
        let formData = new FormData()
        let form = JSON.parse(JSON.stringify(this.form))
        for (const key in form) {
          formData.append(key, this.form[key])
        }
        formData.append('file', this.file)
        formData.append('sign', this.$getMd5Form(form))
        this.$http({
          url: '/api/v2/bureau/import_data',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    },
    checkFile () {
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }).then(res => {
        this.file = res
      })
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  display: none;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>