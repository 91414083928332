<template>
  <div>
    <el-dialog :title=" text + '教师'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               v-if="dialogVisible"
               :rules="rules"
               label-width="110px">
        <el-row :gutter="10"
                style="display:flex;flex-wrap:wrap">
          <el-col :span="12">
            <el-form-item label="手机号"
                          prop="edu_user_mobile">
              <el-input placeholder="请输入手机号"
                        v-model="form.edu_user_mobile"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名"
                          prop="edu_user_name">
              <el-input placeholder="请输入姓名"
                        v-model="form.edu_user_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-if="text == '新增'">
            <el-form-item label="密码"
                          prop="edu_user_password">
              <el-input placeholder="请输入密码"
                        v-model="form.edu_user_password"
                        type="password"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="别名">
              <el-input placeholder="请输入别名"
                        v-model="form.edu_user_nickname"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-if="text == '新增'">
            <el-form-item label="重输密码"
                          prop="edu_user_password1">
              <el-input placeholder="请重输密码"
                        v-model="form.edu_user_password1"
                        type="password"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份"
                          prop="role">
              <el-select v-model="form.role"
                         multiple
                         filterable
                         @change="roleChange"
                         style="width:100%"
                         placeholder="选择身份">
                <el-option v-for="item in roleList"
                           :key="item.id"
                           :label="item.role_name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-if="is_bureau_admin">
            <el-form-item label="所在教育局"
                          prop="edu_bureau_id">
              <el-select v-model="form.edu_bureau_id"
                         style="width:100%"
                         filterable
                         placeholder="请选择教育局">
                <el-option v-for="item in bureauList"
                           :key="item.bureau_id"
                           :label="item.bureau_name"
                           :value="item.bureau_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-else-if="is_agent_admin">
            <el-form-item label="所在代理商"
                          prop="edu_agent_id">
              <el-select v-model="form.edu_agent_id"
                         clearable
                         filterable
                         style="width:100%"
                         placeholder="请选择代理商">
                <el-option v-for="item in agentList"
                           :key="item.agent_id"
                           :label="item.agent_name"
                           :value="item.agent_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-else>
            <el-form-item label="所在学校"
                          prop="edu_school_id">
              <el-select v-model="form.edu_school_id"
                         style="width:100%"
                         filterable
                         @change="schoolChange"
                         placeholder="请选择学校">
                <el-option v-for="item in schoolList"
                           :key="item.school_id"
                           :label="item.school_name"
                           :value="item.school_id + ''">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年级">
              <el-select v-model="form.edu_grade_id"
                         style="width:100%"
                         clearable
                         @change="getClass"
                         placeholder="请选择年级">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="班级">
              <el-select v-model="form.edu_class_id"
                         style="width:100%"
                         clearable
                         multiple
                         placeholder="请选择班级">
                <el-option v-for="item in classList"
                           :key="item.id"
                           :label="item.class_name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="科目">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         multiple
                         placeholder="所有科目都需要的不填">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示详细来源">
              <el-radio v-model="form.is_detailed"
                        @click.native.prevent="checkRadio('is_detailed')"
                        :label="1"> </el-radio>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              default-time="23:59:59"
                              placeholder="截止时间,默认一年">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item label="是管理员">
              <el-radio v-model="form.is_admin"
                        @click.native.prevent="checkRadio('is_admin')"
                        :label="1"> </el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出测试试卷">
              <el-radio v-model="form.is_test_paper"
                        @click.native.prevent="checkRadio('is_test_paper')"
                        :label="1"> </el-radio>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    var checkPwd = (rule, value, callback) => {
      if (this.form.edu_user_password != this.form.edu_user_password1) {
        return callback(new Error('两次密码不一致'));
      } else {
        return callback()
      }
    };
    return {
      dialogVisible: false,
      form: {},
      rules: {
        edu_user_mobile: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        edu_user_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        edu_user_password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        edu_user_password1: [
          { validator: checkPwd, trigger: 'blur', required: true }
        ],
        role: [
          { required: true, message: '请选择身份', trigger: 'blur' },
        ],
        end_time: [
          { required: true, message: '请选择时间', trigger: 'blur' },
        ],
        edu_school_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_class_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_bureau_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_agent_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      schoolList: [],
      subjectList: [],
      bureauList: [],
      gradeList: [],
      agentList: [],
      roleList: [],
      classList: [],
      text: '新增',
      is_bureau_admin: false,
      is_agent_admin: false
    }
  },
  watch: {
    'dialogVisible': {
      handler (newVal, oldVal) {
        if (newVal) {
          if (this.form.edu_agent_id) {
            this.is_agent_admin = true
            this.is_bureau_admin = false
          } else if (this.form.edu_bureau_id) {
            this.is_agent_admin = false
            this.is_bureau_admin = true
          } else {
            this.is_agent_admin = false
            this.is_bureau_admin = false
          }
        }
      }
    }
  },
  methods: {
    checkRadio (str) {
      if (this.form[str] == 1) {
        // this.form[str] = 0
        this.$set(this.form, str, 0)
      } else {
        this.$set(this.form, str, 1)
        // this.form[str] = 1
      }
    },
    schoolChange () {
      if (this.text == '新增') {
        this.form.edu_grade_id = ''
        this.getClassList()
      }
    },
    getClass () {
      this.form.edu_class_id = ''
      if (this.form.edu_school_id == '' || this.form.edu_grade_id == '') return
      this.getClassList()
    },
    getClassList () {

      this.$http({
        url: '/api/v2/public/class_sg',
        method: 'get',
        params: {
          school_id: this.form.edu_school_id,
          grade_id: this.form.edu_grade_id
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subject_id.join(',')
        form.role = form.role.join(',')
        delete form.edu_user_password1
        form.edu_class_id = form.edu_class_id.join(',')
        this.$http({
          url: '/api/v2/bureau/add_teacher',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })

    },
    // checkRadio (str) {
    //   if (this.form[str] == 1) {
    //     this.form[str] = 0
    //   } else {
    //     this.form[str] = 1
    //   }
    // },
    getList () {
      // 获取学校
      this.$http({
        url: '/api/v2/public/enabled_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })

      // 获取科目
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })

      // 教育局
      this.$http({
        url: '/api/v2/bureau/lst',
        method: 'get',
        params: {
          page: 1,
          limit: 10000,
        }
      }).then(res => {
        this.bureauList = res.data.list
      })

      //年级
      this.$http({
        url: '/api/v2/bureau/grade_lst',
        method: 'get',
        params: {
          page: 1,
          limit: 1000,
        }
      }).then(res => {
        this.gradeList = res.data.list
      })

      // 角色
      this.$http({
        url: '/api/v2/bureau/teacher_roles',
        method: 'get',
        params: {
          status: 1
        }
      }).then(res => {
        this.roleList = res.data.list
      })

      // 代理商
      this.$http({
        url: '/api/v2/bureau/agent_lst',
        method: 'get',
        params: {
          page: 1,
          limit: 10000
        }
      }).then(res => {
        this.agentList = res.data.list
      })
    },
    roleChange () {
      let isActive = false
      let isAgent = false
      this.form.role = this.form.role ? this.form.role : []
      this.form.role.forEach(item => {
        if (this.roleList.find(ele => item == ele.id).alias == 'bureau_admin') {
          isActive = true
        }
        if (this.roleList.find(ele => item == ele.id).alias == 'school_agent') {
          isAgent = true
        }
      });
      if (isAgent) {
        this.form.edu_school_id = ''
        this.form.edu_bureau_id = ''
        this.is_bureau_admin = false
        this.is_agent_admin = true
      }
      else if (isActive) {
        this.form.edu_school_id = ''
        this.form.edu_agent_id = ''
        this.is_bureau_admin = true
        this.is_agent_admin = false
      } else {
        this.form.edu_bureau_id = ''
        this.form.edu_agent_id = ''
        this.is_bureau_admin = false
        this.is_agent_admin = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
::v-deep .el-radio__label {
  display: none;
}

</style>